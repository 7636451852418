/* Constants */
import { CTA_TYPE, DR_CTAS } from 'site-modules/shared/constants/inventory/lead-form';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
/* Utils */
import { get } from 'lodash';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isNewDealCheckAvailable } from 'site-modules/shared/utils/car-buying/is-new-deal-check-available';
import { isUsedDealCheckAvailable } from 'site-modules/shared/utils/car-buying/is-used-deal-check-available';
import { isDriveway, isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';

export const CONTACT_OPTION_BY_CTA_NAME = {
  [CTA_TYPE.NEW_LEASE_DR]: 'digitalRetailNewLease',
  [CTA_TYPE.NEW_LOAN_DR]: 'digitalRetailNewLoan',
  [CTA_TYPE.USED_LOAN_DR]: 'digitalRetailUsedLoan',
};

/**
 * Filters out digital retails ctas.
 * @param {string[]} ctas
 * @returns {string[]}
 */
export function filterOutDigitalRetailCtas(ctas) {
  return ctas.filter(cta => !DR_CTAS.includes(cta));
}

/**
 * Checks availability of lease digital retail CTA in CTAs array.
 * @param {boolean} showLease
 * @param {string[]} ctas
 * @returns {boolean}
 */
export function isDigitalRetailLeaseAvailable({ showLease, ctas }) {
  return showLease && ctas.includes(CTA_TYPE.NEW_LEASE_DR);
}

/**
 * Gets correct CTA.
 * @param {string[]} ctas
 * @param {object} inventory
 * @param {boolean} [skip]
 * @param {boolean} [isSrp]
 * @returns {string[]}
 */
export function getInventoryCta({ ctas = [], skip = false, isSrp = false, inventory }) {
  const filteredCtas = filterOutDigitalRetailCtas(ctas);

  if (!skip) {
    const isNewVehicle = isNew(inventory.type);
    const isPartnerListing = isPartnerDealer(inventory);
    const allowModification = isNewVehicle
      ? isNewDealCheckAvailable(inventory)[isSrp ? 'partiallyAvailable' : 'available']
      : isUsedDealCheckAvailable(inventory);

    if (isSrp) {
      if (allowModification) {
        return isNewVehicle ? [CTA_TYPE.NEW.BASE] : [CTA_TYPE.USED.BASE];
      }

      if (isPartnerListing && !isNewVehicle) {
        return [CTA_TYPE.USED.BASE];
      }
    }
  }

  return filteredCtas;
}

/**
 * Gets correct digital retail CTA.
 * @param {string[]} ctas
 * @param {object} inventory
 * @param {boolean} [skip]
 * @param {boolean} [isSrp]
 * @param {boolean} [isDealerSrp]
 * @param {boolean} [showLeasePayments]
 * @returns {string[]}
 */
export function getDigitalRetailCta({
  ctas = [],
  skip = false,
  isSrp = false,
  isDealerSrp = false,
  showLeasePayments = false,
  inventory,
}) {
  // for driveway vehicles we only want to display the cta on car-buying and dealer SRPs
  const isSkipCtaForDriveway =
    isSrp && !isDealerSrp && isDriveway(get(inventory, DATA_PATH.PARENT_DEALERSHIP_NAME, ''));
  if (isSkipCtaForDriveway) {
    return [];
  }

  const filteredCtas = filterOutDigitalRetailCtas(ctas);

  if (!skip) {
    const digitalRetailCtas = ctas.filter(cta => DR_CTAS.includes(cta));

    const isNewVehicle = isNew(inventory.type);
    const isDigitalRetailAvailable = !!digitalRetailCtas.length;
    const isPartnerListing = isPartnerDealer(inventory);
    const isDealCheckAvailable = isNewVehicle
      ? isNewDealCheckAvailable(inventory)[isSrp ? 'partiallyAvailable' : 'available']
      : isUsedDealCheckAvailable(inventory);
    const allowModification = isNewVehicle ? isDealCheckAvailable || isDigitalRetailAvailable : isDealCheckAvailable;
    // if a page is SRP
    if (isSrp) {
      // check that vehicle has availability to display digital retail CTA for new or used
      if (allowModification) {
        return isNewVehicle ? [CTA_TYPE.NEW.BASE] : [CTA_TYPE.USED.BASE];
      }
      // display digital retail CTA for used & partner inventory
      if (isPartnerListing && !isNewVehicle) {
        return [CTA_TYPE.USED.BASE];
      }
    } else {
      // if a page is not SRP check that vehicle has availability to display digital retail CTA for new or used
      if (allowModification) {
        const digitalRetailCta = isDigitalRetailLeaseAvailable({ showLease: showLeasePayments, ctas })
          ? CTA_TYPE.NEW_LEASE_DR
          : CTA_TYPE.NEW_LOAN_DR;
        // filter out all digital retail CTAs because we place selected digital retail CTA into array of CTAs
        return isNewVehicle ? [...filteredCtas, digitalRetailCta] : [...filteredCtas, CTA_TYPE.USED_LOAN_DR];
      }
      // otherwise return CTAs as is
      return ctas;
    }
  }
  // return cleaned array of CTAs without digital retail CTAs if skip is true
  return filteredCtas;
}

/**
 * Modifies contact options to their tracking names.
 * @param {string[]} ctas
 * @returns {string[]}
 */
export function modifyContactOptions(ctas) {
  return ctas.map(cta => (DR_CTAS.includes(cta) ? CONTACT_OPTION_BY_CTA_NAME[cta] : cta));
}
