import { TED_RESTRICTED_MAKES } from 'client/constants/make-exclusions';

/**
 * Transforms option descriptions to array if necessary
 * @param {Array} options
 * @returns {Array} array of transformed option descriptions
 */
export function transformOptionDescriptions(options) {
  if (!Array.isArray(options)) {
    return options[0].split(/[,|]/);
  }

  return options;
}

/**
 * Transforms simple string to Slug string
 * @param {String} str
 * @returns {String}
 */
export const toSlug = str => str.replace(/[\s,_]/g, '-').toLowerCase();

/**
 * Checks passed make name for restrictions.
 * @param {string} make
 * @returns {boolean}
 */
export const isRestrictedMake = make =>
  TED_RESTRICTED_MAKES.some(restrictedMake => restrictedMake.toLowerCase() === make.toLowerCase());
