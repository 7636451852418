import { get } from 'lodash';
/* Constants */
import { CTA_TYPE } from 'site-modules/shared/constants/inventory/lead-form';
/* Utils */
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';

/**
 * Checks if Deal Check can be shown with all price validation attributes or with some part of them.
 * @param {object} vehicle
 * @return {object}
 */
export const isNewDealCheckAvailable = vehicle => {
  const displayPrice = get(vehicle, 'prices.displayPrice');
  const ctas = get(vehicle, 'computedDisplayInfo.ctas', []);
  const { maxGreatPrice, maxGoodPrice, maxFairPrice } = get(vehicle, 'computedDisplayInfo.priceValidation', {});
  // identify if vehicle is eligible for new deal check (has price and at lease 1 CTA is not call dealer)
  const isEligible =
    isNew(vehicle.type) && displayPrice && ctas.length && ctas.some(cta => cta !== CTA_TYPE.CALL_DEALER);
  // identify if price validation data fully available (great, good, fair prices exist)
  const isPriceValidationDataAvailable = maxGreatPrice && maxGoodPrice && maxFairPrice;
  // identify if price validation data partially available (at lease one of great, good, fair prices exist)
  const isPriceValidationDataPartiallyAvailable = maxGreatPrice || maxGoodPrice || maxFairPrice;
  return {
    available: !!(isEligible && isPriceValidationDataAvailable),
    partiallyAvailable: !!(isEligible && isPriceValidationDataPartiallyAvailable),
  };
};
