import { get } from 'lodash';
/* Utils */
import { getDefaultApiHeaders } from 'site-modules/shared/utils/car-buying/api-helper';
import { EventToolbox } from 'client/utils/event-toolbox';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
/* Constants */
import { TrackingConstant } from 'client/tracking/constant';
import { GREAT_DEAL } from 'site-modules/shared/components/deal/deal-constants';

/**
 * Gets TED specific API headers.
 * @param {string} visitorId
 * @returns {function}
 */
export const getApiHeaders = visitorId => () => getDefaultApiHeaders(visitorId);

/**
 * Runs methods from a bound instance.
 */
export async function runInsiderActionAfterSubmit() {
  this.runInsiderAction();
  this.setState({ showSubmitLoadingIndicator: false });
}

/**
 * Checks for a possibility of sending a message for expected page.
 * @param {boolean} [visible]
 * @param {boolean} [checkEligibleInventory]
 * @return {function({pageName: string, leadTargets?: object[]}=): boolean}
 */
export const isDealCheckSMSDeliveryCheckboxVisible = ({ visible = false, checkEligibleInventory = false } = {}) => ({
  pageName,
  leadTargets = [],
} = {}) => {
  if (visible) return true;

  const isAllowedPage = pageName === 'new_model_core';

  if (checkEligibleInventory) {
    return (
      isAllowedPage &&
      leadTargets.some(leadTarget => !!(get(leadTarget, 'inventory.vin') && isNew(get(leadTarget, 'inventory.type'))))
    );
  }

  return isAllowedPage;
};

/**
 * Fires tracking event if `isVisible` is true.
 * @param {*} value
 * @return {function(...[boolean]=)}
 */
export const fireDealCheckCheckboxVisibilityTrackingEvent = value => isVisible => {
  if (isVisible) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
      event_data: {
        action_name: TrackingConstant.ACTION_WIDGET_VIEW,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: TrackingConstant.SEND_TO_PHONE,
        value,
      },
    });
  }
};

/**
 * Returns used vehicle titles for lead form.
 * @param {object} vehicle
 * @return {{subheader: string, tooltip: string, header: string}}
 */
export const getUsedLeadFormTitles = vehicle => {
  const displayPrice = get(vehicle, 'prices.displayPrice');
  const { maxGreatPrice, maxGoodPrice, maxFairPrice, dealType } = get(
    vehicle,
    'computedDisplayInfo.priceValidation',
    {}
  );

  const lowerBoundPrice = maxGreatPrice || maxGoodPrice || maxFairPrice;

  if (displayPrice < lowerBoundPrice) {
    return {
      header: "The dealer's online price is off the charts! Literally.",
      subheader: "We recommend you act fast if you want this deal. Rate the dealer's final offer.",
      tooltip:
        'This is a great deal and there may not be room to negotiate. On average, the final sale price of used cars is 7% less than the online price.',
    };
  } else if (dealType === GREAT_DEAL) {
    return {
      header: "The dealer's online price is well below what we'd expect!",
      subheader: "Use this form to get the dealer's lowest out-the-door price so you can rate it.",
      tooltip:
        'This is a great deal and there may not be room to negotiate. On average, the final sale price of used cars is 7% less than the online price.',
    };
  }

  return {
    header: "Most dealers can't post their lowest prices online.",
    subheader: "Use this form to get the dealer's lowest out-the-door price so you can rate it.",
    tooltip: 'On average, the final sale price of used cars is 7% less than the online price.',
  };
};
