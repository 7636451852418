import { get, isFinite, isObject, isNil, find } from 'lodash';
import dateFormat from 'dateformat';

/* Utils */
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { transformOptionDescriptions } from 'site-modules/shared/utils/car-buying/vehicle-util';
import { getValidationFormMessages } from 'site-modules/shared/utils/calculator/calculator-helper';
import { getTradeInValidationErrorMessage } from 'site-modules/shared/utils/car-buying/calculator-util';
import { showTrimName } from 'site-modules/shared/utils/show-trim-name';

/* Constants */
import { VALUE_NOT_AVAILABLE } from 'site-modules/shared/constants';
import {
  LIMIT_TRADE_IN,
  TOTAL_TRADE_IN_MSG,
  APPLIED_TRADE_IN_MSG,
} from 'site-modules/shared/constants/calculator/calculator';
import { SPECIAL_LOCATION_PARAMS } from 'client/site-modules/shared/constants/car-buying';

const QUERY_DELIMETERS = ',';
const MULTI_PARAM_DELIMITER = '~';
const DEFAULT_OBJECT = {};

/**
 * Create Purchase message
 * @param {object} leaseData
 * @param {string} initialString
 * @returns {string}
 */
const createPurchaseOfferText = (leaseData, initialString) => {
  let bodyString = initialString;

  if (isFinite(leaseData.monthlyPayment)) {
    bodyString += `Loan Payment: ${formatPriceString(leaseData.monthlyPayment)}/mo*\n`;
  }

  if (isFinite(leaseData.downPayment)) {
    bodyString += `Down Payment: ${formatPriceString(leaseData.downPayment)}*\n`;
  }

  if (isFinite(leaseData.msrp)) {
    bodyString += `\nMSRP: ${formatPriceString(leaseData.msrp)}\n`;
  }

  if (isFinite(leaseData.totalSavings)) {
    bodyString += `Edmunds' Estimated Savings: -${formatPriceString(leaseData.totalSavings)}\n`;
  }

  if (isFinite(leaseData.term)) {
    bodyString += `\nTerm: ${leaseData.term} months\n`;
  }

  if (isFinite(leaseData.tradeIn)) {
    bodyString += `Trade-In: ${formatPriceString(leaseData.tradeIn)}\n`;
  }

  if (isFinite(leaseData.baseMonthlyPayment)) {
    bodyString += `Est. Base Payment: ${formatPriceString(leaseData.baseMonthlyPayment)}/mo\n`;
  }

  if (leaseData.capitalizedTaxes && isFinite(leaseData.capitalizedTaxes.combinedSalesTax)) {
    bodyString += `\nTaxes: ${formatPriceString(leaseData.capitalizedTaxes.combinedSalesTax)}\n`;
  }

  if (leaseData.capitalizedFees && isFinite(leaseData.capitalizedFees.combinedFees)) {
    bodyString += `Fees: ${formatPriceString(leaseData.capitalizedFees.combinedFees)}\n`;
  }

  if (isFinite(leaseData.apr) && isFinite(leaseData.term)) {
    bodyString += `APR: ${leaseData.apr.toFixed(1)}% for ${leaseData.term} mo\n`;
  }

  if (leaseData.endDateEpochMillis) {
    bodyString += `\n*Estimates valid through ${dateFormat(leaseData.endDateEpochMillis, 'mm/dd/yyyy')}.\n`;
  }

  return `${bodyString}\n`;
};

/**
 * Create Lease message
 * @param {object} leaseData
 * @param {string} initialString
 * @returns {string}
 */
const createLeaseOfferText = (leaseData, initialString) => {
  let bodyString = initialString;

  if (isFinite(leaseData.monthlyPayment)) {
    bodyString += `Est. Lease Payment: ${formatPriceString(leaseData.monthlyPayment)}/mo*\n`;
  }

  if (isFinite(leaseData.dueAtSigning)) {
    bodyString += `Est. Due at signing: ${formatPriceString(leaseData.dueAtSigning)}*\n`;
  }

  if (isFinite(leaseData.msrp)) {
    bodyString += `\nMSRP: ${formatPriceString(leaseData.msrp)}\n`;
  }

  if (isFinite(leaseData.totalSavings)) {
    bodyString += `Edmunds' Estimated Savings: -${formatPriceString(leaseData.totalSavings)}\n`;
  }

  if (isFinite(leaseData.estimatedPurchasePrice)) {
    bodyString += `Est. Purchase Price: ${formatPriceString(leaseData.estimatedPurchasePrice)}\n`;
  }

  if (isFinite(leaseData.term)) {
    bodyString += `\nTerm: ${leaseData.term} months\n`;
  }

  if (isFinite(leaseData.annualMileage)) {
    bodyString += `Annual Mileage: ${leaseData.annualMileage}\n`;
  }

  if (isFinite(leaseData.tradeIn)) {
    bodyString += `Trade-In: ${formatPriceString(leaseData.tradeIn)}\n`;
  }

  if (isFinite(leaseData.downPayment)) {
    bodyString += `Down Payment: ${formatPriceString(leaseData.downPayment)}\n`;
  }

  if (isFinite(leaseData.baseMonthlyPayment)) {
    bodyString += `Est. Base Payment: ${formatPriceString(leaseData.baseMonthlyPayment)}/mo\n`;
  }

  if (leaseData.capitalizedTaxes && isFinite(leaseData.capitalizedTaxes.combinedSalesTax)) {
    bodyString += `\nTaxes: ${formatPriceString(leaseData.capitalizedTaxes.combinedSalesTax)}\n`;
  }

  if (leaseData.capitalizedFees && isFinite(leaseData.capitalizedFees.combinedFees)) {
    bodyString += `Fees: ${formatPriceString(leaseData.capitalizedFees.combinedFees)}\n`;
  }

  if (isFinite(leaseData.apr) && isFinite(leaseData.term)) {
    bodyString += `APR: ${leaseData.apr.toFixed(1)}% for ${leaseData.term} mo\n`;
  }

  if (isFinite(leaseData.residualValue)) {
    bodyString += `Residual Value: ${formatPriceString(leaseData.residualValue)}\n`;
  }

  if (isFinite(leaseData.amountFinanced)) {
    bodyString += `Est. Amount Financed: ${formatPriceString(leaseData.amountFinanced)}\n`;
  }

  if (leaseData.endDateEpochMillis) {
    bodyString += `\n*Estimates valid through ${dateFormat(leaseData.endDateEpochMillis, 'mm/dd/yyyy')}.\n`;
  }

  return `${bodyString}\n`;
};

/**
 * Create delimited string from object's parameters
 * @param {string} targetObject
 * @param {string} keyValueSeparator
 * @param {string} delimiter
 * @param {string} filterFunc
 * @returns {string}
 */
const createDelimitedStringFromObject = (targetObject, keyValueSeparator, delimiter, filterFunc) =>
  Object.keys(targetObject)
    .filter(f => filterFunc(targetObject[f]))
    .map(k => `${k}${keyValueSeparator}${targetObject[k]}`)
    .join(delimiter);

/**
 * Create percent encoded URL that contains query parameters
 * @param {array} queryParams
 * @param {bool} isPurchase
 * @returns {string}
 */
const createUrlWithQueryParams = queryParams => {
  // filter function allows for truthy primitives and zero (because Trade-In can be zero)
  const filterFunc = val => !isObject(val) && (isFinite(val) || val);
  const filteredParameters = createDelimitedStringFromObject(queryParams, '=', '&', filterFunc);
  return filteredParameters;
};

/**
 * Create array from delimited parameter (e.g. a~1,b~2 => [ [a,1], [b:2] ])
 * @param {string} targetObject
 * @param {string} filterFunc
 * @returns {string}
 */
const convertDelimitedParamToArray = (targetObject, filterFunc) =>
  targetObject
    .split(QUERY_DELIMETERS)
    .map(str => str.split(MULTI_PARAM_DELIMITER))
    .filter(array => filterFunc(array));

/**
 * Returns Trade-in details for sharing.
 * @param tradeInDetails
 * @param limitCriteria
 * @param pubState
 * @returns {*|{tradeInAppliedAmount: *, limitPercentage: *, pubState: *, appraisalVehicle: (*|string)}}
 */
export const extractTradeInDetailsForShare = (tradeInDetails, limitCriteria, pubState) => {
  const { tradeInLimitDescription, appraisalVehicle, tradeInEquityDescription, ...restTradeInDetails } = tradeInDetails;
  return {
    ...restTradeInDetails,
    appraisalVehicle: appraisalVehicle ? encodeURIComponent(appraisalVehicle) : '',
    limitPercentage: get(limitCriteria, 'limitPercentage'),
    tradeInAppliedAmount: get(find(get(limitCriteria, 'limits'), { paymentType: LIMIT_TRADE_IN }), 'appliedAmount'),
    pubState,
  };
};

/**
 * Normalizes Trade-in details data.
 * @param {object} tradeInDetails
 * @returns {object}
 */
const normalizeTradeInDetails = tradeInDetails => {
  const {
    limitPercentage,
    tradeInAppliedAmount,
    pubState,
    appraisalValue,
    amountOwed,
    appraisalVehicle,
    appliedTradeInEquity,
    tradeInEquity,
  } = tradeInDetails;

  let decodedAppraisalVehicle = '';
  let tradeInEquityDescription = '';

  if (appraisalVehicle) {
    decodedAppraisalVehicle = decodeURIComponent(appraisalVehicle);
    tradeInEquityDescription = getTradeInValidationErrorMessage(appraisalValue - amountOwed, TOTAL_TRADE_IN_MSG);
  }

  return {
    appraisalValue: Number(appraisalValue) || 0,
    amountOwed: Number(amountOwed) || 0,
    appraisalVehicle: decodedAppraisalVehicle,
    tradeInLimitDescription: tradeInAppliedAmount
      ? `${APPLIED_TRADE_IN_MSG} ${
          getValidationFormMessages(
            { limitPercentage, limits: [{ paymentType: LIMIT_TRADE_IN, appliedAmount: Number(tradeInAppliedAmount) }] },
            pubState
          ).tradeInValidationError
        }`
      : APPLIED_TRADE_IN_MSG,
    tradeInEquityDescription,
    appliedTradeInEquity: Number(appliedTradeInEquity) || 0,
    tradeInEquity: Number(tradeInEquity) || 0,
  };
};

/**
 * Extract and calculate the relevant data from the vehicle object
 * @param {object} vehicle
 * @returns {object}
 */
export const extractVehicleData = vehicle => {
  const data = {};

  const {
    vehicleInfo: { styleInfo, partsInfo, vehicleColors },
    prices,
  } = vehicle;

  const { exterior, interior } = vehicleColors;
  const { year, make, model, trim } = styleInfo;
  const { standardFacets = [], optionDescriptions = [], options = [], engineSize, cylinders } = partsInfo;
  const featuresAndSpecs = [...transformOptionDescriptions(optionDescriptions), ...standardFacets].filter(
    feature => !!feature
  );

  // resolve vehicle data
  data.styleLine1 = `${year} ${make} ${model} ${showTrimName(trim) ? trim : ''}`;
  data.year = year;
  data.make = make;
  data.model = model;
  data.trim = trim;
  data.exteriorColor = exterior.name;
  data.interiorColor = interior.name;
  data.msrp = get(prices, 'displayPrice');
  data.options = options;
  data.featuresAndSpecs = featuresAndSpecs;

  data.engineSize = engineSize;
  data.engineCylinder = cylinders;

  const mpgCity = get(styleInfo, 'fuel.epaCityMPG', VALUE_NOT_AVAILABLE);
  const mpgHighway = get(styleInfo, 'fuel.epaHighwayMPG', VALUE_NOT_AVAILABLE);

  data.mpgCity = mpgCity;
  data.mpgHighway = mpgHighway;
  data.styleMPG = `${mpgCity} city / ${mpgHighway} hwy`;
  data.styleLine2 = `${engineSize}-Liter ${cylinders}-Cylinder`;

  return data;
};

/**
 * Extract and calculate the relevant data from the calculatedOffer object
 * @param {object} calculatedOffer
 * @param {number} msrp
 * @returns {object}
 */
export const extractOfferData = (calculatedOffer, msrp) => {
  const {
    monthlyPayment,
    dueAtSigning,
    totalRebate,
    sellingPrice,
    term,
    downPayment,
    annualMileage,
    apr,
    paymentWithoutTaxes: { baseMonthlyPayment },
    paymentWithTaxes,
    endDateEpochMillis,
    residual,
    incentives,
    moneyFactor,
    docFee,
    tradeIn,
  } = calculatedOffer;

  const capitalizedFees = get(paymentWithTaxes, 'capitalizedFees', {});
  const amountFinanced = get(paymentWithTaxes, 'amountFinanced');

  const offerData = {};

  offerData.paymentWithTaxes = paymentWithTaxes;
  offerData.monthlyPayment = monthlyPayment;
  offerData.dueAtSigning = dueAtSigning;
  offerData.totalRebate = totalRebate;
  offerData.sellingPrice = sellingPrice;
  offerData.term = term;
  offerData.downPayment = downPayment;
  offerData.annualMileage = annualMileage;
  offerData.apr = apr;
  offerData.baseMonthlyPayment = baseMonthlyPayment;
  offerData.residual = residual;
  offerData.incentives = incentives;
  offerData.endDateEpochMillis = endDateEpochMillis;
  offerData.moneyFactor = moneyFactor;
  offerData.docFee = docFee;
  offerData.msrp = msrp;
  offerData.tradeIn = tradeIn;

  // calculatations
  const marketAdjustment = msrp - sellingPrice;
  offerData.marketAdjustment = marketAdjustment;
  offerData.totalSavings = totalRebate + marketAdjustment;
  offerData.estimatedPurchasePrice = sellingPrice - totalRebate;
  offerData.amountFinanced = amountFinanced;
  const { dealerFee, acquisitionFee } = capitalizedFees;
  offerData.residualValue = msrp * residual;
  offerData.acquisitionFee = acquisitionFee;
  offerData.dealerFee = dealerFee;
  offerData.capitalizedFees = get(paymentWithTaxes, 'capitalizedFees', {});
  offerData.capitalizedTaxes = get(paymentWithTaxes, 'capitalizedTaxes', {});

  return offerData;
};

/**
 * Create long deal offer in plain text
 * @param {object} vehicle
 * @param {object} calculatedOffer
 * @param {object} dealerInfo
 * @param {bool} isPurchase
 * @param {bool} isUsed
 * @returns {string}
 */
export const createLongDealOfferText = (vehicle, calculatedOffer, dealerInfo, isPurchase, isUsed) => {
  if (!calculatedOffer || !dealerInfo || !dealerInfo.dealerData) {
    return '';
  }

  const vehicleData = extractVehicleData(vehicle);
  const leaseData = extractOfferData(calculatedOffer, vehicleData.msrp);

  const { name, phone, city, stateCode } = dealerInfo.dealerData;

  let bodyString = `Here is my ${isUsed ? 'deal' : 'Edmunds Deal'} for my ${vehicleData.year} ${vehicleData.make} ${
    vehicleData.model
  } ${showTrimName(vehicleData.trim) ? vehicleData.trim : ''} with ${vehicleData.exteriorColor} exterior and interior ${
    vehicleData.interiorColor
  }:\n\n`;

  if (name) {
    bodyString += `Dealer: ${name}`;
    if (city && stateCode) {
      bodyString += ` (${city}, ${stateCode})\n`;
    } else {
      bodyString = `${bodyString}\n`;
    }
  }

  if (phone) {
    bodyString += `Contact: ${phone}\n\n`;
  }

  return isPurchase ? createPurchaseOfferText(leaseData, bodyString) : createLeaseOfferText(leaseData, bodyString);
};

/**
 * Create Param string to Review Page
 * @param {object} vehicle
 * @param {object} calculatedOffer
 * @param {string} storedZipCode
 * @param {boolean} isPurchase
 * @param {object} tradeInDetails
 * @param {object} additionalData
 * @returns {string}
 */
export const getShareReviewParams = (
  vehicle,
  calculatedOffer,
  storedZipCode,
  isPurchase,
  tradeInDetails = DEFAULT_OBJECT,
  additionalData = DEFAULT_OBJECT
) => {
  if (!calculatedOffer || !storedZipCode) {
    return null;
  }

  const msrp = get(vehicle, 'prices.displayPrice');
  const offerData = extractOfferData(calculatedOffer, msrp);

  // Email client will decode Percent Encoding (e.g. %20 to spaces).
  // We need to prevent this, else improper URLs will appear in the email client.
  // So we encode our strings

  const strIncentives = offerData.incentives
    ? offerData.incentives
        .map(i =>
          encodeURIComponent(
            `${i.programName}${MULTI_PARAM_DELIMITER}${i.cash}${MULTI_PARAM_DELIMITER}${i.expirationDate}`
          )
        )
        .join(QUERY_DELIMETERS)
    : '';

  const purchaseParams = {
    apr: offerData.apr,
  };

  const leaseParams = {
    annualMileage: offerData.annualMileage,

    moneyFactor: offerData.moneyFactor,
    residual: offerData.residual,

    endDateEpochMillis: offerData.endDateEpochMillis,
    acquisitionFee: offerData.acquisitionFee,
  };

  const taxesAndFees = {
    capitalizedFees: offerData.capitalizedFees
      ? createDelimitedStringFromObject(offerData.capitalizedFees, MULTI_PARAM_DELIMITER, QUERY_DELIMETERS, isFinite)
      : '',
    capitalizedTaxes: offerData.capitalizedTaxes
      ? createDelimitedStringFromObject(offerData.capitalizedTaxes, MULTI_PARAM_DELIMITER, QUERY_DELIMETERS, isFinite)
      : '',
  };

  const params = {
    monthlyPayment: offerData.monthlyPayment,
    dueAtSigning: offerData.dueAtSigning,

    msrp,
    totalSavings: offerData.totalSavings,
    incentives: strIncentives,
    marketAdjustment: offerData.marketAdjustment,
    purchasePrice: offerData.sellingPrice - offerData.totalRebate,

    term: offerData.term,
    downPayment: offerData.downPayment,
    baseMonthlyPayment: offerData.baseMonthlyPayment,

    amountFinanced: offerData.amountFinanced,

    zipCode: storedZipCode,

    tradeInDetails: createDelimitedStringFromObject(
      tradeInDetails,
      MULTI_PARAM_DELIMITER,
      QUERY_DELIMETERS,
      val => !isNil(val)
    ),
    tradeIn: offerData.tradeIn,
    dealerFee: offerData.dealerFee,
    ...taxesAndFees,

    ...(isPurchase ? purchaseParams : leaseParams),

    ...additionalData,
  };

  return createUrlWithQueryParams(params);
};

/**
 * Converts shared link query parameters to correct data types.
 * @param {object} query
 * @param {object} isPurchase
 * */
export const normalizeQueryParameters = (query, isPurchase) => {
  const normalizedQuery = {};

  normalizedQuery.monthlyPayment = Number(query.monthlyPayment);
  normalizedQuery.dueAtSigning = Number(query.dueAtSigning);
  normalizedQuery.msrp = Number(query.msrp);
  normalizedQuery.totalSavings = Number(query.totalSavings);
  normalizedQuery.marketAdjustment = Number(query.marketAdjustment);
  normalizedQuery.purchasePrice = Number(query.purchasePrice);
  normalizedQuery.term = Number(query.term);

  normalizedQuery.downPayment = Number(query.downPayment);
  normalizedQuery.baseMonthlyPayment = Number(query.baseMonthlyPayment);
  normalizedQuery.amountFinanced = Number(query.amountFinanced);

  normalizedQuery.zipCode = query.zipCode;
  normalizedQuery.acquisitionFee = Number(query.acquisitionFee);
  normalizedQuery.dealerFee = Number(query.dealerFee);
  if (query.creditScore) normalizedQuery.creditScore = Number(query.creditScore);

  normalizedQuery.tradeIn = Number(query.tradeIn);

  const filterFuncSizeTwo = arr => arr.length === 2;
  const filterFuncSizeThree = arr => arr.length === 3;

  let incentives = [];
  if (query.incentives) {
    incentives = convertDelimitedParamToArray(query.incentives, filterFuncSizeThree).map((item, index) => ({
      programName: item[0],
      cash: Number(item[1]),
      expirationDate: Number(item[2]),
      incentiveId: index,
      programId: `${index}`,
    }));
  }
  normalizedQuery.incentives = incentives;

  if (query.tradeInDetails) {
    normalizedQuery.tradeInDetails = normalizeTradeInDetails(
      convertDelimitedParamToArray(query.tradeInDetails, filterFuncSizeTwo).reduce(
        (a, b) => Object.assign(a, { [b[0]]: b[1] }),
        {}
      )
    );
  }

  normalizedQuery.apr = Number(query.apr);

  const paymentWithTaxes = {};
  if (query.capitalizedFees) {
    paymentWithTaxes.capitalizedFees = convertDelimitedParamToArray(query.capitalizedFees, filterFuncSizeTwo).reduce(
      (a, b) => Object.assign(a, { [b[0]]: Number(b[1]) }),
      {}
    );
  }

  if (query.capitalizedTaxes) {
    paymentWithTaxes.capitalizedTaxes = query.capitalizedTaxes;
    paymentWithTaxes.capitalizedTaxes = convertDelimitedParamToArray(query.capitalizedTaxes, filterFuncSizeTwo).reduce(
      (a, b) => Object.assign(a, { [b[0]]: Number(b[1]) }),
      {}
    );
  }
  normalizedQuery.paymentWithTaxes = paymentWithTaxes;

  if (!isPurchase) {
    normalizedQuery.annualMileage = Number(query.annualMileage);
    normalizedQuery.moneyFactor = Number(query.moneyFactor);
    normalizedQuery.residual = Number(query.residual || query.residualPerc);
    normalizedQuery.endDateEpochMillis = Number(query.endDateEpochMillis);
  }

  normalizedQuery.redirect = query[SPECIAL_LOCATION_PARAMS.REDIRECT];
  normalizedQuery.smsTarget = query[SPECIAL_LOCATION_PARAMS.TARGET];

  return normalizedQuery;
};
