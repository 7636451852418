import { get, sortBy } from 'lodash';
/* Utils */
import {
  extractTradeInDetailsForShare,
  getShareReviewParams,
} from 'site-modules/shared/utils/car-buying/review-page-share-utils';
import { getTradeInDetails } from 'site-modules/shared/utils/car-buying/calculator-util';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { EventToolbox } from 'client/utils/event-toolbox';
/* Constants */
import { SPECIAL_LOCATION_PARAMS, LEASE, PURCHASE } from 'site-modules/shared/constants/car-buying';
import { DEAL_CHECK } from 'site-modules/shared/constants/car-buying/steps';
import { TrackingConstant } from 'client/tracking/constant';
/* API */
import { MobileRestApiClient } from 'client/data/api/api-client';

const { REDIRECT, TARGET } = SPECIAL_LOCATION_PARAMS;

/**
 * Sends SMS.
 * @param {string} to - phoneNumber
 * @param {string} url - url to short
 * @param {string} ttl - 30d or 7d
 * @param {string} messageType - responsible for message and number mapping on BE
 * @return {JSON}
 */
function sendSMS({ to, url, ttl, messageType }) {
  return MobileRestApiClient.fetchJson('/sms/messages', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ messageType, to, shortUrl: { url, ttl } }),
  });
}

/**
 * Sends a message with DealCheck link.
 * @param {string} vin
 * @param {string} phoneNumber
 * @param {boolean} isPurchase
 * @param {boolean} hasOptOut
 * @param {string} params
 * @return {Promise<void>}
 */
async function sendDealCheckSMS({ vin, phoneNumber, isPurchase = false, params = '' }) {
  const type = isPurchase ? PURCHASE : LEASE;
  const urlToShort = `${window.location.origin}/digital-retail/my-edmunds-deal/${vin}/?${type}&${params}`;

  await sendSMS({ to: phoneNumber, url: urlToShort, ttl: '30d', messageType: 'TED' });

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_SHARE_CONTENT,
      subaction_name: TrackingConstant.SEND_TO_PHONE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: TrackingConstant.SEND_TO_PHONE,
      value: "Compare Dealer's Best Prices",
    },
  });
}

/**
 * Finds new inventory in lead targets.
 * @param {object[]} leadTargets
 * @returns {object[]|null}
 */
function findNewInventoryLeadTarget(leadTargets) {
  const targets = leadTargets
    .filter(leadTarget => !!(get(leadTarget, 'inventory.vin') && isNew(get(leadTarget, 'inventory.type'))))
    .map(({ inventory, leadAttributes }) => ({ vin: inventory.vin, distance: leadAttributes.distance }));

  return targets.length ? targets : null;
}

/**
 * Sends sms with deal check link by passed offer.
 * @param {object} selections
 * @param {object[]} deals
 * @param {object} vehicle
 * @param {string} zipCode
 * @param {boolean} isPurchase
 * @param {object} personalInfo
 * @returns {Promise<void>}
 */
export async function sendDealCheckSMSByOffer({
  additionalData: { selections, deals, vehicle, zipCode, isPurchase },
  personalInfo,
}) {
  const { type } = vehicle;
  const { dealOffer } = deals[0];
  const { limitCriteria, tradeIn } = dealOffer;

  // extract trade-in details for share
  const tradeInDetailsDataForShare = extractTradeInDetailsForShare(
    getTradeInDetails({
      selections,
      appliedTradeIn: tradeIn,
      limitCriteria,
      pubState: type,
    }),
    limitCriteria,
    type
  );

  // configure share param
  const params = getShareReviewParams(vehicle, dealOffer, zipCode, isPurchase, tradeInDetailsDataForShare, {
    [REDIRECT]: DEAL_CHECK,
    [TARGET]: 'lead',
    creditScore: selections.creditScore,
    tradeIn: selections.tradeIn,
  });

  await sendDealCheckSMS({ vin: vehicle.vin, phoneNumber: personalInfo.phone, isPurchase, params });
}

/**
 * @callback sendDealCheckSMSByLeadTargetsCallback
 * @param {object[]} leadTargets
 * @param {object[]} selectedLeadTargets
 * @param {object} personalInfo
 * @returns {Promise<void>|void}
 */
/**
 * Sends sms with deal check link by passed lead targets
 * @param {string} currentTest
 * @param {string} target
 * @return {sendDealCheckSMSByLeadTargetsCallback}
 */
export const sendDealCheckSMSByLeadTargets = ({ vin, target }) => async ({
  leadTargets = [],
  selectedLeadTargets = [],
  personalInfo,
}) => {
  const currentVin =
    vin ||
    get(
      sortBy(findNewInventoryLeadTarget(selectedLeadTargets) || findNewInventoryLeadTarget(leadTargets), 'distance'),
      '[0].vin'
    );

  if (currentVin) {
    await sendDealCheckSMS({
      vin: currentVin,
      phoneNumber: personalInfo.phone,
      isPurchase: true,
      params: `${REDIRECT}=${DEAL_CHECK}&${TARGET}=${target}`,
    });
  }
};
