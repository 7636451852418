import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, merge } from 'lodash';
import {
  HEADER_COMPONENT,
  LEAD_FORM_VIEWS,
  LEAD_TYPES,
  SUB_HEADER_COMPONENT,
} from 'site-modules/shared/constants/lead-form/lead-form-params';
import { isAMSI } from 'site-modules/shared/utils/dealer-details-utils';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { TITLES } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/multi-digital-retail-offer-type';
import { getLeadSubmissionInfoWithoutEmail, getPathname } from 'site-modules/shared/components/lead-form/utils/utils';
import { sendMarketoThankYouEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
import { getLeadFormCreativeId } from 'client/site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';

export function NciSrpLeadForm({ params, options }) {
  const {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    pageName,
    isNewVin,
    formName,
    specialIdentifier,
    bodyType,
    priceStrikethrough,
    dealerName,
  } = params;
  const { photoUrl, toggleDrawer, isInStockOnline } = options;

  const customConfig = {
    leadType: LEAD_TYPES.INVENTORY,
    creativeId: getLeadFormCreativeId({
      isNewVin,
      pageNameLegacy: pageName,
      formName: formName || LEAD_FORMS_CREATIVE_ID.DEALER_FORM_SRP,
    }),
    leadFormComponent: LEAD_FORM_VIEWS.NCI_SRP,
    leadFormConfig: {
      disableInventoryCard: true,
      showInventoryMakeStockImage: true,
      submitButtonText: 'Get Dealer Offer',
      headerConfig: {
        headerComponent: HEADER_COMPONENT.CONVEX,
        subHeaderComponent: noop,
      },
      tcpaParentDealershipName: isAMSI(dealerName) ? dealerName : null,
      title: TITLES.MULTI.HEADER_NEW.TITLE_SRP_GET_DEALER_BEST_PRICE,
      subtitle: 'Enter your contact information to get this dealer’s offer before you head to the dealership!',
      withFormClasses: false,
      photoUrl,
      bodyType,
      priceStrikethrough,
      isInStockOnline,
      specialIdentifier,
      toggleDrawer,
    },
    // TODO: Remove when marketo email is triggered on backend
    processDataMethods: {
      postProcessMethods: [sendMarketoThankYouEmail],
      getLeadSubmissionInfo: getLeadSubmissionInfoWithoutEmail,
    },
    thankYouConfig: {
      headerConfig: {
        subHeaderComponent: SUB_HEADER_COMPONENT.BASE,
        subHeader: {
          text: 'You’re All Set',
          explainingText:
            'Thanks for submitting your request for the {year} {make} {model}. Your information has been sent and the dealer should follow up shortly',
          insiderLinkOut: true,
        },
      },
    },
  };

  const optionsConfig = get(options, 'customConfig', {});
  const formProps = {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    pathname: getPathname(options),
    customConfig: merge({}, customConfig, optionsConfig),
  };

  return <UnifiedLeadFormAsync {...formProps} />;
}

NciSrpLeadForm.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
};
