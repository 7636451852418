const MERCEDES_BENZ = 'Mercedes-Benz';

export const NOT_STRIKETHROUGH_MAKES = [
  'Acura',
  'Audi',
  'BMW',
  'Cadillac',
  'Hyundai',
  'INFINITI',
  'Jaguar',
  'Land Rover',
  'Lexus',
  'Maserati',
  MERCEDES_BENZ,
  'Porsche',
];
export const LIMITED_TIME_OFFER_MAKES = ['Nissan', 'INFINITI'];

export const DISABLE_DISCOUNT_MAKES = ['INFINITI'];

export const TED_RESTRICTED_MAKES = [MERCEDES_BENZ];

export const DESTINATION_FEE_SHORT_COPY_MAKES = ['acura'];
