/**
 * Gets digital retail tags.
 * @param {boolean} isPurchase
 * @param {boolean} willSendCreditReport
 * @param {boolean} isCreditApproved
 * @returns {string[]}
 */
export const getDigitalRetailTags = ({ isPurchase, willSendCreditReport, isCreditApproved }) => {
  const tags = ['#digital_retail'];

  if (isPurchase) {
    tags.push('#cbp-loan');

    if (willSendCreditReport) {
      tags.push(isCreditApproved ? '#credit_prequalified' : '#credit_not_qualified');
    }
  } else {
    tags.push('#cbp-lease');
  }

  return tags;
};

/**
 * Get deal check tags.
 * @param {boolean} [isPromo]
 * @return {string[]}
 */
export const getDealCheckTags = ({ isPromo } = {}) => {
  let tags = ['#cbp', '#dealcheckprice'];

  if (isPromo) tags = [...tags, '#dealcheckpromo'];

  return tags;
};
