import { NciLeadForm } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/nci-lead-form';
import { UciLeadForm } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/uci-lead-form';
import { MultiDigitalRetailOfferTypeLeadForm } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/multi-digital-retail-offer-type-lead-form';
import { MultiDigitalRetailOfferTypeLeadFormSrpNew } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/multi-digital-retail-offer-type-lead-form-srp-new';
import { MultiDigitalRetailOfferTypeLeadFormSrpUsed } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/multi-digital-retail-offer-type-lead-form-srp-used';
import { NciSrpLeadForm } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/nci-srp-lead-form';
import { LEAD_FORM_NAMES } from './lead-form-names';

export const LEAD_FORMS = {
  [LEAD_FORM_NAMES.NCI]: NciLeadForm,
  [LEAD_FORM_NAMES.UCI]: UciLeadForm,
  [LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE]: MultiDigitalRetailOfferTypeLeadForm,
  [LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW]: MultiDigitalRetailOfferTypeLeadFormSrpNew,
  [LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_USED]: MultiDigitalRetailOfferTypeLeadFormSrpUsed,
  [LEAD_FORM_NAMES.NCI_SRP]: NciSrpLeadForm,
};
