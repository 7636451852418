import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { merge } from 'site-modules/shared/components/lead-form/utils/merge';

import { LEAD_TYPES, SUB_HEADER_COMPONENT } from 'site-modules/shared/constants/lead-form/lead-form-params';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
import { getLeadSubmissionInfoWithoutEmail, getPathname } from 'site-modules/shared/components/lead-form/utils/utils';
import { sendMarketoThankYouEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
import { getLeadFormCreativeId } from 'client/site-modules/shared/components/lead-form/utils/lead-form-creative-id';

export function NciLeadForm({ params, options }) {
  const {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    pageNameLegacy,
    isNewVin,
    formName,
  } = params;

  const customConfig = {
    leadType: LEAD_TYPES.INVENTORY,
    creativeId: getLeadFormCreativeId({
      isNewVin,
      pageNameLegacy,
      formName: formName || LEAD_FORMS_CREATIVE_ID.SINGLE_BASE_VDP_FORM,
    }),
    leadFormConfig: {
      disableInventoryCard: true,
      showInventoryMakeStockImage: true,
      submitButtonText: 'Get Dealer Offer',
      headerConfig: {
        subHeaderComponent: SUB_HEADER_COMPONENT.BASE,
        subHeader: {
          text: 'Get Dealer Offer',
          explainingText:
            'Enter your contact information to get this dealer’s offer before you head to the dealership!',
        },
      },
      tcpaParentDealershipName: get(options, 'tcpaParentDealershipName'),
      options: {
        layoutOptions: {
          userInfoColSize: { xs: 12, md: 8 },
        },
      },
      userInfoOptions: {
        isLabelInside: true,
      },
    },
    // TODO: Remove when marketo email is triggered on backend
    processDataMethods: {
      postProcessMethods: [sendMarketoThankYouEmail],
      getLeadSubmissionInfo: getLeadSubmissionInfoWithoutEmail,
    },
    thankYouConfig: {
      headerConfig: {
        subHeaderComponent: SUB_HEADER_COMPONENT.BASE,
        subHeader: {
          text: 'You’re All Set',
          explainingText:
            'Thanks for submitting your request for the {year} {make} {model}. Your information has been sent and the dealer should follow up shortly',
          insiderLinkOut: true,
        },
      },
    },
  };

  const optionsConfig = get(options, 'customConfig', {});
  const formProps = {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    pathname: getPathname(options),
    customConfig: merge({}, customConfig, optionsConfig),
  };
  return <UnifiedLeadFormAsync {...formProps} />;
}

NciLeadForm.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
};
