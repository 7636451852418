import { mergeWith } from 'lodash';

/*
Lodash merge function returns truncated React component like Symbol(react.memo)
that can causes cyclical re-creation component some cases

$$typeof: Symbol(react.memo)
WrappedComponent: props => {…}
compare: null
displayName: "Connect(Component)"
type: ƒ ConnectFunction(props)
__proto__: Object

Symbol(react.memo) should be like

$$typeof: Symbol(react.memo)
WrappedComponent: props => {…}
compare: null
displayName: "Connect(Component)"
type: ƒ ConnectFunction(props)
apply: ƒ apply()
bind: ƒ bind()
call: ƒ call()
Symbol(Symbol.hasInstance): ƒ [Symbol.hasInstance]()
__proto__: Object

So, customizer function returns React component as is
*/

function customizer(objValue, srcValue) {
  // This tag allows us to uniquely identify this as a React component
  if (srcValue && srcValue.$$typeof) {
    return srcValue;
  }
  return undefined;
}

export function merge(...args) {
  return mergeWith(...args, customizer);
}
