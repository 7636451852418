export const TITLES = {
  MULTI: {
    HEADER: {
      TITLE: "Want to know if you're getting a good deal?",
      SUBTITLE: 'Rate competing offers from local dealers.',
    },
    HEADER_NEW: {
      TITLE: 'Are you getting a good price?',
      TITLE_SRP: 'Check Availability ',
      TITLE_SRP_GET_DEALER_BEST_PRICE: 'Get Dealer’s Best Price',
      TITLE_SRP_CHECK_PRICING: 'Check Pricing',
      TITLE_SRP_GET_OFFER: 'Get Dealer Offer',
      SUBTITLE: '',
    },
    LEAD_FORM: {
      TITLE: "Most dealers can't post their lowest prices online.",
      SUBTITLE: "Use this form to get these dealers' lowest out-the-door price so you can rate them.",
    },
    BUTTON: "Compare Dealers' Best Prices",
  },
  SINGLE: {
    HEADER: {
      TITLE: "Want to know if you're getting a good deal?",
      SUBTITLE: "Rate the dealer's out-the-door price.",
    },
    HEADER_NEW: {
      TITLE: 'Are you getting a good price?',
      TITLE_SRP: 'Check Availability ',
      SUBTITLE: '',
    },
    LEAD_FORM: {
      TITLE: "Most dealers can't post their lowest prices online.",
      SUBTITLE: "Use this form to get the dealer's lowest out-the-door price so you can rate it.",
    },
    BUTTON: "Get Dealer's Price",
  },
};

export const SRP_NEW_VIN_BUTTON = 'Request Information';
