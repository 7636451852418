import React from 'react';
import {
  HEADER_COMPONENT,
  LEAD_TYPES,
  LOADING_COMPONENT,
} from 'site-modules/shared/constants/lead-form/lead-form-params';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { sendDealCheckSMSByLeadTargets } from 'site-modules/shared/utils/car-buying/sms';
import { getLeadSubmissionInfoWithoutEmail, getPathname } from 'site-modules/shared/components/lead-form/utils/utils';
import { isDealCheckSMSDeliveryCheckboxVisible } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/digital-retail-lead-form-utils';
import { isDefaultZipCode } from 'site-modules/shared/utils/inventory/is-default-zip-code';

export const Stub = () => null;
const TOP_CORE_UTM_CAMPAIGN = 'core_top_lead_form';

// TODO Delete after updating thank you page to new design
export const ThankYouHeader = () => <div className="bg-primary-darker py-1_25 py-md-2 pos-a left-0 top-0 w-100" />;

export function getMultiDigitalRetailLeadFormPropsCommon({
  params,
  options,
  visitorId,
  pageName,
  postProcessMethods,
  isNewVin,
  isUserSetZip,
}) {
  const {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    styleId,
    trim,
    specialIdentifier,
    bodyType,
    priceStrikethrough,
    skipWidgetViewTracking,
    dealerName,
    creativeId,
  } = params;

  const {
    target,
    daysToSell,
    tcpaParentDealershipName,
    bestDealPrice,
    showLeasePayments,
    isLeasePaymentEnabled,
    preSelectedCardNumbers,
    showInTransit,
    photoUrl,
    toggleDrawer,
    isInStockOnline,
  } = options;

  const priceCheckerParamsObj = {
    utm_targetid: visitorId,
    utm_account: 'edmunds_insider',
    utm_campaign: TOP_CORE_UTM_CAMPAIGN,
    bestDealPrice,
  };

  const customConfig = {
    leadType: LEAD_TYPES.ANY,
    creativeId:
      creativeId ||
      getLeadFormCreativeId({
        isNewVin,
        pageNameLegacy: pageName,
        formName: LEAD_FORMS_CREATIVE_ID.DEAL_CHECK_PRICE_MULTI_DRAWER,
      }),

    apiParams: {
      leadTargetsSize: 2,
    },
    processDataMethods: {
      processCheckedCheckbox: sendDealCheckSMSByLeadTargets({
        vin,
        target: target || 'lead',
      }),
      // TODO: Remove when marketo email is triggered on backend TODO
      ...{ postProcessMethods: [postProcessMethods], getLeadSubmissionInfo: getLeadSubmissionInfoWithoutEmail },
    },

    leadFormConfig: {
      priceStrikethrough,
      checkbox: {
        isVisible: isDealCheckSMSDeliveryCheckboxVisible({ visible: false }),
      },
      headerConfig: {
        headerComponent: HEADER_COMPONENT.CONVEX,
        subHeaderComponent: Stub,
      },
      withFormClasses: false,
      daysToSell,
      skipWidgetViewTracking,
      tcpaParentDealershipName,
      showLeasePayments: showLeasePayments && isLeasePaymentEnabled,
      preSelectedCardNumbers, // TODO: remove when cleanup blt-2066-nci-basic-v1
      dealerName,
      photoUrl,
      toggleDrawer,
      isInStockOnline,
    },
    loadingComponent: LOADING_COMPONENT.CONVEX,
    thankYouConfig: {
      priceStrikethrough,
      headerConfig: {
        headerComponent: ThankYouHeader,
        subHeaderComponent: Stub,
      },
      withFormClasses: false,
      priceCheckerParams: priceCheckerParamsObj,
      hideCtas: true,
      showInTransit,
    },
  };

  return {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    styleId,
    trim,
    specialIdentifier,
    bodyType,
    zipCode,
    radius,
    customConfig,
    pathname: getPathname(options),
    config: {
      dataTrackingParent: {
        creativeId: customConfig.creativeId,
      },
    },
    showZipForm: !isUserSetZip && isDefaultZipCode(zipCode),
    zipFormHeaderCopy: '',
  };
}
