import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LEAD_FORMS } from 'site-modules/shared/constants/lead-form/lead-forms';

/**
 * Unified lead form wrapper is used to open lead form by name.
 * @param {string} lead form name
 * @param {object} params
 * @param {object} params
 * @returns {ReactElement}
 */
export class UnifiedLeadFormWrapper extends Component {
  constructor(props) {
    super(props);
    this.leadFormContainer = React.createRef();
  }

  render() {
    const { formName, params, options, className } = this.props;
    const UnifiedLeadForm = LEAD_FORMS[formName];

    return (
      <div ref={this.leadFormContainer}>
        <UnifiedLeadForm params={params} options={options} className={className} />
      </div>
    );
  }
}

UnifiedLeadFormWrapper.propTypes = {
  formName: PropTypes.string.isRequired,
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}),
  className: PropTypes.string,
};

UnifiedLeadFormWrapper.defaultProps = {
  options: {},
  className: '',
};

export function getUnifiedLeadFormByName(formName, params, options) {
  return {
    component: UnifiedLeadFormWrapper,
    data: { formName, params, options },
  };
}
