import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
/* Constants */
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import {
  LEAD_FORM_VIEWS,
  LEAD_TYPES,
  THANK_YOU_VIEWS,
  USER_INFO_POSITION,
} from 'site-modules/shared/constants/lead-form/lead-form-params';
import { DELIVERY_CODES } from 'site-modules/shared/components/delivery/constants';

/* Components */
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
/* Utils */
import { getLeadSubmissionInfoWithoutEmail, getPathname } from 'site-modules/shared/components/lead-form/utils/utils';
import { sendDealCheckSMSByLeadTargets } from 'site-modules/shared/utils/car-buying/sms';
import { isDealCheckSMSDeliveryCheckboxVisible } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/digital-retail-lead-form-utils';
import { getDealCheckTags } from 'site-modules/shared/utils/lead-form/tags';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { isInstockOnline } from 'site-modules/shared/utils/dealer-details-utils';
import { getCardView } from 'client/site-modules/shared/components/lead-form/unified-lead-form-pages/multi-digital-retail-offer-type-lead-form-utils';

/* Models */
import {
  sendMarketoThankYouEmail,
  sendUsedInventoryMarketoThankYouEmail,
} from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';

export const Stub = () => null;
const TOP_CORE_UTM_CAMPAIGN = 'core_top_lead_form';

export function MultiDigitalRetailOfferTypeLeadFormUI({ params, options, pageName, visitorId, isMobile }) {
  const {
    vin,
    make,
    model,
    year,
    distance,
    deliveryCode,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    styleId,
    style,
    trim,
    specialIdentifier,
    bodyType,
    priceStrikethrough,
    isNewVin,
    isUsedSrpDealCheck,
    isNewVinOnSrp,
    skipWidgetViewTracking,
    isInventoryDeal,
    dealerName,
    creativeId,
  } = params;
  const {
    isSrp,
    isPromo,
    target,
    daysToSell,
    tcpaParentDealershipName,
    bestDealPrice,
    showLeasePayments,
    isLeasePaymentEnabled,
    preSelectedCardNumbers,
    isVdpPartnerListing,
    isDealerSrp,
    showInTransit,
    photoUrl,
  } = options;

  const config =
    isNewVinOnSrp && isSrp && !isDealerSrp ? LEAD_FORM_CONFIGS.UNIFIED_PURCHASE : LEAD_FORM_CONFIGS.UNIFIED;

  const isDeliveryAvailable = DELIVERY_CODES.includes(deliveryCode) && !!zipCode;
  const isInStockOnline = !isNewVin && isInstockOnline({ distance, radius, isDeliveryAvailable });

  const nonInvDealTags = isNewVinOnSrp ? null : getDealCheckTags({ isPromo });
  const tags = isInventoryDeal ? ['#hand-picked'] : nonInvDealTags;

  const explainingTextParams = [
    {
      name: 'trim',
      value: trim,
    },
    {
      name: 'style',
      value: style,
    },
  ];
  const priceCheckerParamsObj = {
    utm_targetid: visitorId,
    utm_account: 'edmunds_insider',
    utm_campaign: TOP_CORE_UTM_CAMPAIGN,
    bestDealPrice,
  };

  const postProcessMethods = isNewVin ? sendMarketoThankYouEmail : sendUsedInventoryMarketoThankYouEmail;

  const isNewVinOnSrpMobile = isNewVinOnSrp && isMobile;
  const customConfig = {
    leadType: LEAD_TYPES.ANY,
    creativeId:
      creativeId ||
      getLeadFormCreativeId({
        isNewVin,
        pageNameLegacy: pageName,
        formName: LEAD_FORMS_CREATIVE_ID.DEAL_CHECK_PRICE_MULTI_DRAWER,
      }),
    tags: isUsedSrpDealCheck ? null : tags,
    apiParams: {
      leadTargetsSize: isVdpPartnerListing ? 0 : 2,
    },
    processDataMethods: {
      processCheckedCheckbox: sendDealCheckSMSByLeadTargets({
        vin,
        target: target || 'lead',
      }),
      // TODO: Remove when marketo email is triggered on backend
      ...(isInventoryDeal
        ? {}
        : { postProcessMethods: [postProcessMethods], getLeadSubmissionInfo: getLeadSubmissionInfoWithoutEmail }),
    },
    leadFormComponent: LEAD_FORM_VIEWS.MULTI_DIGITAL_RETAIL_OFFER_TYPE,
    leadFormConfig: {
      priceStrikethrough,
      cardView: getCardView({ isUsedSrpDealCheck, isInventoryDeal }),
      checkbox: {
        isVisible: isDealCheckSMSDeliveryCheckboxVisible({ visible: false }),
      },
      headerConfig: {
        headerComponent: Stub,
        subHeaderComponent: Stub,
      },
      withFormClasses: false,
      daysToSell,
      isUsedSrpDealCheck,
      skipWidgetViewTracking,
      isNewVinOnSrp,
      tcpaParentDealershipName,
      isInventoryDeal,
      userInfoPosition: isNewVinOnSrpMobile ? 1 : USER_INFO_POSITION.AFTER_CARDS,
      isPriceChecker: !isUsedSrpDealCheck && !isInventoryDeal,
      showLeasePayments: showLeasePayments && isLeasePaymentEnabled,
      preSelectedCardNumbers, // TODO: remove when cleanup blt-2066-nci-basic-v1
      dealerName,
      isInStockOnline,
      photoUrl,
    },
    thankYouComponent:
      isUsedSrpDealCheck || isInventoryDeal
        ? THANK_YOU_VIEWS.DEAL_CHECK_AVAILABILITY
        : THANK_YOU_VIEWS.SRP_PRICE_CHECKER,
    thankYouConfig: {
      priceStrikethrough,
      headerConfig: {
        headerComponent: Stub,
        subHeaderComponent: Stub,
      },
      withFormClasses: false,
      priceCheckerParams: priceCheckerParamsObj,
      hideCtas: true,
      isNewVinOnSrp,
      showInTransit,
    },
    explainingTextParams: isUsedSrpDealCheck ? undefined : explainingTextParams,
  };

  const formProps = {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    styleId,
    trim,
    specialIdentifier,
    bodyType,
    zipCode,
    radius,
    customConfig,
    pathname: getPathname(options),
    config: {
      ...config,
      dataTrackingParent: {
        creativeId: customConfig.creativeId,
      },
    },
  };

  return (
    <Fragment>
      <UnifiedLeadFormAsync {...formProps} />
    </Fragment>
  );
}

MultiDigitalRetailOfferTypeLeadFormUI.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  pageName: PropTypes.string,
  visitorId: PropTypes.string,
  isMobile: PropTypes.bool,
};

MultiDigitalRetailOfferTypeLeadFormUI.defaultProps = {
  pageName: '',
  visitorId: null,
  isMobile: false,
};

export const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  visitorId: get(state, 'visitor.id'),
  isMobile: state.mobile,
});

export const MultiDigitalRetailOfferTypeLeadForm = connect(mapStateToProps)(MultiDigitalRetailOfferTypeLeadFormUI);
